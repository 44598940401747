$themes: (
  diva-cloud-light: (
    diva-blue: #379be5,
    diva-light-blue: #b9dcf7,
    diva-green: #00acc1,
    diva-indigo-blue: #5c6bc0,
    diva-orange: #eb672c,
    diva-pink: #e658a1,
    diva-violet: #ab47bc,
    diva-yellow: #fdd835,
    diva-lime-green: #5cc100,
    diva-error-red: #d0021b,
    diva-light-green: #d0f3f7,
    diva-light-indigo-blue: #cce5f4,
    diva-light-orange: #ffe7dd,
    diva-light-pink: #ffe3f1,
    diva-light-violet: #f6d4fc,
    diva-light-yellow: #fff8db,
    diva-light-lime-green: #defbc3,
    diva-medium-gray: #d8d8d8,
    diva-dark-gray: #cccccc,
    diva-navy: #443f5f,
    diva-dark-blue: #205a85,
    diva-black: #141720,
    diva-light-black: #2d313d,
    diva-bg-light-blue: #e2eff9,
    diva-light-gray: #f5f5f5,
    diva-white: #ffffff,
  ),
);

@mixin themify($themes) {
  @each $theme, $map in $themes {
    :host-context(.theme-#{$theme}) & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@mixin themifyNoHostContext($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

@mixin regular-button {
  font-weight: 700;
  @include themify($themes) {
    background-color: themed("diva-blue");
    border-radius: 4px;
    font-size: 14px;
  }

  @include themifyNoHostContext($themes) {
    background-color: themed("diva-blue");
    border-radius: 4px !important;
    font-size: 14px;
  }
}

@mixin regular-button-disabled {
  font-weight: 700;
  @include themify($themes) {
    background-color: themed("diva-blue");
    color: themed("diva-white");
    opacity: 0.25;
    font-size: 14px;
  }

  @include themifyNoHostContext($themes) {
    background-color: themed("diva-blue");
    color: themed("diva-white");
    opacity: 0.25;
    font-size: 14px;
  }
}

@mixin regular-button-outline {
  font-weight: 700;
  @include themify($themes) {
    border-color: themed("diva-blue");
    border-radius: 4px;
    border-width: 1px;
  }

  @include themifyNoHostContext($themes) {
    border-color: themed("diva-blue");
    border-radius: 4px;
    border-width: 1px;
  }
}

@mixin regular-button-outline-disabled {
  font-weight: 700;
  @include themify($themes) {
    border-color: themed("diva-blue");
    color: themed("diva-white");
    border-radius: 4px;
    border-width: 1px;
    opacity: 0.25;
  }

  @include themifyNoHostContext($themes) {
    border-color: themed("diva-blue");
    color: themed("diva-white");
    border-radius: 4px;
    border-width: 1px;
    opacity: 0.25;
  }
}

@mixin delete-button {
  font-weight: 700;
  @include themify($themes) {
    background-color: themed("diva-error-red");
    border-radius: 4px;
    font-size: 14px;
  }

  @include themifyNoHostContext($themes) {
    background-color: themed("diva-error-red");
    border-radius: 4px;
    font-size: 14px;
  }
}

@mixin cancel-button {
  font-weight: 700;
  @include themify($themes) {
    border-color: themed("diva-navy");
    border-radius: 4px;
    border-width: 1px;
  }

  @include themifyNoHostContext($themes) {
    border-color: themed("diva-navy");
    border-radius: 4px;
    border-width: 1px;
  }
}

@mixin snakbar-warn {
  @include themifyNoHostContext($themes) {
    background-color: themed("diva-white") !important;

    border-width: 20px;
    max-width: 450px;
    padding: 18px 18px;

    .snack-msg {
      font-weight: bold;
      color: themed("diva-navy");
    }

    .snack-icon {
      color: themed("diva-yellow");
    }

    .snack-close {
      color: themed("diva-navy");
    }
  }
}

@mixin snakbar-error {
  @include themifyNoHostContext($themes) {
    background-color: themed("diva-error-red");
    max-width: 450px;
    padding: 18px 18px;

    .snack-msg {
      color: themed("diva-white");
    }

    .snack-icon {
      color: themed("diva-white");
    }

    .snack-close {
      color: themed("diva-white");
    }
  }
}

@mixin snakbar-success {
  @include themifyNoHostContext($themes) {
    background-color: themed("diva-white");
    max-width: 450px;
    padding: 18px 18px;

    .snack-msg {
      color: themed("diva-navy");
    }

    .snack-icon {
      color: themed("diva-lime-green");
    }

    .snack-close {
      color: themed("diva-navy");
    }
  }
}

@mixin mat-icon-overflow {
    @include themifyNoHostContext($themes) {
      overflow: visible;
    }
}