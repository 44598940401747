@import "diva-cloud-theme";

//Set default font family
* {
  font-family: "Open Sans", "Noto Sans JP";
  font-size: 13px;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-size: 13px;
}

h1, h2, h3 {
  margin: 0;
  font-size: 16px !important;
  margin-bottom: 16px;
}

.mat-drawer-content {
  margin-left: 80px;
}

.mat-drawer-opened {
  + .mat-drawer-content {
    margin-left: 290px;
  }
}

/*Custom css for components generated*/
.myPanelClass {
  margin: 25px 0px;
}

.snakbar-error {
  padding: 20px;
  background-color: red !important;
  color: white;
}

@-webkit-keyframes spin {
  0% {
    transform: rotate(0)
  }

  100% {
    transform: rotate(360deg)
  }
}

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0)
  }

  100% {
    -moz-transform: rotate(360deg)
  }
}

@keyframes spin {
  0% {
    transform: rotate(0)
  }

  100% {
    transform: rotate(360deg)
  }
}

.wrap-global-spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden
}

.wrap-content {
  position: relative;
  top: 10%;
  left: 50%;
}

.spinner div:first-child {
  display: block;
  width: 70px;
  height: 70px;
  margin: 20px 0 0 -30px;
  border-radius: 50%;
  box-shadow: 0 3px 3px 0 rgba(255,56,106,1);
  transform: translate3d(0,0,0);
  animation: spin 2s linear infinite
}

.spinner div:first-child:after, .spinner div:first-child:before {
  content: '';
  position: absolute;
  border-radius: 50%
}

.spinner div:first-child:before {
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  box-shadow: 0 3px 3px 0 rgb(255, 228, 32);
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite
}

.spinner div:first-child:after {
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  box-shadow: 0 3px 3px 0 rgba(61, 175, 255,1);
  animation: spin 1.5s linear infinite
}

.wrap-conflow-and-icon {
  margin-left: -250px;
}

.conflow-loading {
  color: black;
  font-size: 80px;
  font-family: auto;
}

.splash-screen-logoicon-loading {
  display: inline-block;
  text-indent: -9999px;
  background-repeat: no-repeat;
  background-size: 500px 500px;
  width: 500px;
  height: 500px;
}

.splash-screen-logoicon-white {
  background-image: url('assets/images/divacloud_logo_white.png');
}

.splash-screen-logoicon-dark {
  background-image: url('assets/images/divacloud_logo.png');
}


/*Angular Material Table*/
.mattable {
  height: auto;
  border-radius: 4px 4px 0px 0px;
}

.mattable .mat-mdc-header-row {
  min-height: 50px;
  height: fit-content;
  background-color: #205a85;
  border-radius: 4px 4px 0px 0px;
}

.mattable .mat-mdc-row {
  border-bottom: 1px solid #d8d8d8;
  border-left: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
}

.mattable .mat-mdc-header-cell {
  border-bottom: none;
  background-color: #205a85;
  color: #fff;
  padding-right: 15px;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px 4px 0px 0px;
}

.mattable .mat-mdc-cell {
  border-right: none;
  background-color: #fff;
  color: #443f5f;
  padding-top: 5px;
  padding-right: 15px;
  padding-bottom: 5px;
  font-size: 14px;
  display: flex;
  align-items: center;
  white-space: normal;
  word-break: break-word;
  cursor: pointer;
  border-bottom: none;
}

.mattable .mat-mdc-row.row-selected {
  background: #f1f1f1 !important;
  color: #443f5f !important;

  .mat-mdc-cell {
    background: #f1f1f1 !important;
    color: #443f5f !important;
  }
}

.mattable.no-row-selection .mat-mdc-row:hover {
  background: none !important;
  cursor: auto;

  .mat-mdc-cell {
    background: none !important;
    cursor: auto;
  }
}

.mattable .mat-sort-header[aria-sort="ascending"]  .mat-sort-header-arrow {
  position: relative;
  display: inline-block;
  border: none;
  width: 20px;
  height: 20px;
  background: url(/assets/icons/up-chevron.svg);
  background-size: 10px 10px;
  background-repeat: no-repeat;
  background-position: 5px;
}

.mattable .mat-sort-header[aria-sort="descending"]  .mat-sort-header-arrow {
  position: relative;
  display: inline-block;
  border: none;
  width: 20px;
  height: 20px;
  background: url(/assets/icons/up-chevron.svg);
  background-size: 10px 10px;
  background-repeat: no-repeat;
  background-position: 5px 3px;
  transform: rotate(180deg) !important;
}

.mattable .mat-sort-header-stem,
.mattable .mat-sort-header-indicator, 
.mattable .mat-sort-header-left, 
.mattable .mat-sort-header-right,
.mattable .mat-sort-header-pointer-middle {
  display: none;
}

.inviteMemberDialog {
  position: relative !important;
}

.inviteMemberDialog .mat-mdc-dialog-container {
  padding: 0;
}

.mat-icon {
  @include mat-icon-overflow;
}